import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import ('../views/Home.vue')
  },
  {
    path: '/app/',
    component: () => import ('../views/Home.vue')
  },
  {
    path: '/app/contentpage/:id',
    component: () => import ('../views/Page.vue')
  },
  {
    path: '/app/category/:id',
    component: () => import ('../views/Category.vue')
  },
  {
    path: '/app/videos/:id',
    component: () => import ('../views/ArticleVideos.vue')
  },
  {
    path: '/app/coronavirus/:id',
    component: () => import ('../views/ArticleCoronavirus.vue')
  },
  {
    path: '/app/congress/:id',
    component: () => import ('../views/ArticleCongress.vue')
  },
  {
    path: '/app/polls/:id',
    component: () => import ('../views/ArticlePolls.vue')
  },
  {
    path: '/app/policy/:id',
    component: () => import ('../views/ArticlePolicy.vue')
  },
  {
    path: '/app/ubio/:id',
    component: () => import ('../views/ArticleUbio.vue')
  },
  {
    path: '/app/opinion/:id',
    component: () => import ('../views/ArticleOpinion.vue')
  },
  {
    path: '/app/news/:id',
    component: () => import ('../views/Article.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
